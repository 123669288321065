import React, { useState } from "react";
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-analytics';


//import css
import "./../../styles/integrations-slack.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const OpsgenieIntegrationPage = ({ data }) => (
  <Layout>
    <SEO
      title="Connect Opsgenie to your Odown monitors"
      description="Receive real-time alerts from Odown to your Opsgenie account."
      pathname={`/integrations/opsgenie/`}
      image={{
        src: "/general-image-og--odown_integration_Opsgenie.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection imageData={data.FeatureBreakDownImage1.childImageSharp.fluid}/>
    <Description />
    <CombinedSections />
    <CombinedSections2 />
    <Howtouse />
    <SuperchargeYourIncidentResponseSection />
    <BuiltforTeamsofAllSizes />
    <SecureandReliable />
    <TryOdownSection />
    <ClosingSection />
    <GetStartedBanner />
  </Layout>
)

const HeadingSection = ({ imageData }) => {
    const [getStartedButton, setGetStartedButton] = useState({
      name: "Get started for free",
      link: "https://app.odown.io/signup",
    });
  
    return (
      <div className="u-features slack-hero">
        <div className="flex-container">
          <div className="text-section">
            <h1 className="custom-heading-title">
            Seamlessly Manage Website Odowntime with Odown's Opsgenie Integration            </h1>
            <div className="u-features__heading-btn center-button">
              <OutboundLink
                className="custom-btn custom-btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blank"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
          <div className="image-section">
            <Img
              fadeIn={false}
              loading="eager"
              fluid={imageData}
              alt="Integration - Opsgenie"
            />
          </div>
        </div>
      </div>
    );
  };  
const Description = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <p className="u-main-feature_tc-desc2">
    Website monitoring is an advanced technique that has 
    greater significance than traditional monitoring 
    methods that are used for different tasks. Odown 
    provides Opsgenie Integration that simplifies website 
    monitoring by offering various features which assist 
    in completing your monitoring requirements.       
    </p>
     </div>
  </section>
)

const CombinedSections = ({ imageData }) => (
    <div className="sections-container">
        <h2 className="centered-heading">Why Connect Odown with Opsgenie?</h2>
        <div className="real-time-section">
            <RealTimeNotificationSection imageData={imageData} />
        </div>
        <div className="streamlined-section">
            <StreamlinedIncidentManagementSection imageData={imageData} />
        </div>
    </div>
);

  const RealTimeNotificationSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Real-Time Alerts</h3>
              <p className="u-main-feature_tc-desc">
              Currently Odown can perform most monitoring tasks for example 
              it can identify Odowntime quickly, with the help of different 
              notification systems you can execute various actions like 
              phone calls, SMS, and also deliver emails to your team members. 
              These alert systems make your monitoring very effective by 
              providing you different possibilities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const StreamlinedIncidentManagementSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Streamlined Incident Management</h3>
              <p className="u-main-feature_tc-desc">
              Streamlined Incident Management in Opsgenie enhances efficiency and organization. 
              Currently, such tools require proper understanding, and initially they need 
              correct setup for incident details and now continuous updates too. 
              These tools provide many features such as, clear ownership and also natural 
              elements like chat integration which represent good management.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

const CombinedSections2 = () => (
    <div className="sections-container">
        <div className="real-time-section">
            <CustomizableForYourWorkflowSection />
        </div>
        <div className="streamlined-section">
            <A360ViewOfPerformanceSection />
        </div>
    </div>
);

const CustomizableForYourWorkflowSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Customizable for Your Workflow</h3>
              <p className="u-main-feature_tc-desc">
              Incorrect alert configuration is very challenging during incidents. 
              Different methods are implemented and with the help of proper routing 
              they create very organized alerts that is very beneficial for teams. 
              Also, teams get advantages from this as they implement proper severity 
              levels and use correct techniques to handle incidents.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const A360ViewOfPerformanceSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">A 360° View of Your Website's Performance</h3>
              <p className="u-main-feature_tc-desc">
              Extended monitoring can provide many insights that is very crucial for website health. 
              Today you discover many metrics like response times and user experience and as such 
              can be valuable in the current monitoring. This monitoring according to experts is 
              extremely important to website performance and this shows just how crucial proper checking is.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const Howtouse = () => {
    return (
      <section className="u-main-feature integrations-slack-section" style={{ padding: '30px 20px' }}>
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <h2 className="content-title1">Simple Setup, Quick Results</h2>
              <p className="u-main-feature_tc-desc2">
              Integration setup brings many advantages to monitoring work. 
              Presently integration can help in quick setup and easy configuration, 
              with the help of different methods like simple clicks you can perform 
              various tasks safely. These methods make your integration very 
              organized by giving you different possibilities.
              </p>
    
              <p className="u-main-feature_tc-desc2">
              Opsgenie Integration with Odown is a modern monitoring system that 
              has more features than traditional monitoring systems used for various tasks. 
              Presently this integration can handle most monitoring tasks through these steps:
              </p>
              <h2 className="content-title1">Integration Setup:</h2>
              <p className="use-desc2">
              - Navigate to Integrations section in Odown dashboard
              </p>
              <p className="use-desc2">
              - Choose Opsgenie and link accounts
              </p>
              <p className="use-desc2">
              - Modify alert preferences              
              </p>
              <p className="use-desc2">
              - Begin continuous monitoring           
              </p>

              <p className="u-main-feature_tc-desc2">
              These monitoring systems simplify incident management by providing diverse 
              possibilities which help to complete your monitoring tasks.
              </p>
    
            </div>
          </div>
        </div>
      </section>
    );
  };
  

  const SuperchargeYourIncidentResponseSection = () => {
    const features = [
      {
        title: "Fast targeted notifications",
      },
      {
        title: "Central incident management",
      },
      {
        title: "Clear tracking system",
      },
    ];
  
    return (
      <section className="u-advanced-features" style={{ padding: '30px 20px' }}>
        <div className="container">
          <h2 className="content-title1">Incident Response Benefits:</h2>
          <div className="u-advanced-features__items">
          <p className="u-main-feature_tc-desc2">
          This system really caught attention during monitoring time when 
          teams need fast respond for incidents. Many of those alerts include 
          many features and different patterns which are created from monitoring 
          tools. Today, such systems give:
       </p>
            <ul>
              {features.map((feature, index) => (
                <li key={index}>
                  <div className="u-a-features__item-wrapper">
                    <div className="u-a-features__item--title">
                      {feature.title}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  };
 
  const BuiltforTeamsofAllSizes = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Team Size Compatibility :</h2>
    <p className="u-main-feature_tc-desc2">
    Different size teams can use this system that is highly beneficial for incident management:
       </p>
       <p className="u-main-feature_tc-desc2">
       - Small teams receive simple setup
       </p>
       <p className="u-main-feature_tc-desc2">
       - Large teams get advanced features
       </p>
   
     </div>
  </section>
)


const SecureandReliable = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Security Features :</h2>
    <p className="u-main-feature_tc-desc2">
    Bad security is very dangerous during monitoring period. 
    Different security methods is used and with the help of proper 
    encryption they make very safe monitoring that is quite helpful for teams.  
       </p>
     </div>
  </section>
)


const TryOdownSection = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Try Integration Today :</h2>
    <p className="u-main-feature_tc-desc2">
    Extended Odowntime is very problematic for websites. Also, teams are 
    affected from this as they take time to respond and use different tools 
    to fix problems. Today you find this integration on things like performance 
    tracking and uptime monitoring and as such can come in handy in the current world.
       </p>
     </div>
  </section>
)

const ClosingSection = () => (
    <div className="sections-container-closing" style={{ padding: '30px 20px' }}>
        <h2 className="centered-heading">
        Support Available:
        </h2>
        <p className="u-main-feature_tc-desc2">
        This support according to experts is extremely crucial to success and 
        this proves just how important proper setup is and how they sustain 
        monitoring traditions. These services many depicts different patterns 
        and beautiful solutions containing stories of the uptime and about 
        team's efficiency and response.
       </p>
    </div>
);


export const query = graphql`
  query {
    FeatureBreakDownImage1: file(
      relativePath: { eq: "opsgenie_icon.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    
  }
`

export default OpsgenieIntegrationPage